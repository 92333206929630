<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-button type="primary" plain size="mini" v-if="quartzSearchFlag" @click="quartzSearch">查询</el-button>
                        <el-button type="primary" plain size="mini" v-if="quartzAddFlag" @click="quartzAdd">新增</el-button>
                        <el-button type="primary" plain size="mini" v-if="quartzModifyFlag" @click="quartzModify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="quartzDelFlag" @click="quartzDel">删除</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog
                :title="dialogquartzConfigtitile"
                :visible.sync="dialog_quartzConfig"
                width="75%"
                :modal="true"
                center
                :before-close="handleClose_quartzConfig">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">定时配置信息</span>
                </div>
                <div>
                    <el-form :model="quartzConfigForm"  ref="quartzConfigForm" :rules="quartzConfigFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="定时器名称" prop="jobName">
                                    <el-input v-model="quartzConfigForm.jobName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="状态" prop="status">
                                    <el-select v-model="quartzConfigForm.status" placeholder="请选择状态">
                                        <el-option label="启用" value="1"></el-option>
                                        <el-option label="冻结" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="启动方式" prop="autoStart">
                                    <el-select v-model="quartzConfigForm.autoStart" placeholder="请选择启动方式">
                                        <el-option label="自启动" value="1"></el-option>
                                        <el-option label="手动启动" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="服务组" prop="serverId">
                                    <el-select v-model="quartzConfigForm.serverId" placeholder="请选择服务组">
                                        <el-option label="城市配" value="1"></el-option>
                                        <el-option label="未分配" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="cron表达式" prop="cronExpression">
                                    <el-input v-model="quartzConfigForm.cronExpression"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="定时器类名" prop="className">
                                    <el-input v-model="quartzConfigForm.className"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="业务表述" prop="description">
                                    <el-input v-model="quartzConfigForm.description"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_quartzConfig">提 交</el-button>
                <el-button @click="handleClose_quartzConfig">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import axios from 'axios'
    import arrayQuery from '../../components/arrayQuery.js'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                dialogquartzConfigtitile:'',
                dialog_quartzConfig:false,
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                quartzConfigForm:{
                    quartzId:'',
                    jobName:'',
                    className:'',
                    description:'',
                    cronExpression:'',
                    status:'',
                    autoStart:'',
                    serverId:''
                },
                quartzConfigFormrules:{
                    jobName: [
                        { required: true, message: '请输入定时器名称', trigger: 'blur' }
                    ],
                    className: [
                        { required: true,message: '请输入定时器类名', trigger: 'blur' }
                    ],
                    cronExpression: [
                        { required: true,message: '请输入定时器cron表达式', trigger: 'blur' }
                    ],
                    status: [
                        { required: true, message: '请选择定时器状态', trigger: 'blur' }
                    ],
                    autoStart: [
                        { required: true, message: '请选择定时器启动方式', trigger: 'blur' }
                    ],
                    serverId: [
                        { required: true, message: '请选择分配模块', trigger: 'blur' }
                    ]
                },
                tableCols:[ //表格列数据
                    {label:'定时器ID',prop:'quartzId',width:'160',align:'center'},
                    {label:'定时器任务名称',prop:'jobName',width:'160',align:'center'},
                    {label:'类名',prop:'className',align:'center'},
                    {label:'任务描述',prop:'description',align:'center'},
                    {label:'cron表达式',prop:'cronExpression',align:'center',width:'120'},
                    {label:'状态',prop:'status',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.status == false) {
                                return "冻结";
                            } else if (row.status == true) {
                                return "启用";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'启动方式',prop:'autoStart',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.autoStart == false) {
                                return "手动启动";
                            } else if (row.autoStart == true) {
                                return "自启动";
                            } else {
                                return "";
                            }
                        }
                    }
                ],
                pagination:{ //分页数据
                    pageSize:10,
                    pageNum:1,
                    total:0
                },
                merge:[],
                quartzSearchFlag:true,
                quartzAddFlag:true,
                quartzModifyFlag:true,
                quartzDelFlag:true
            }
        },

        components:{ //引入组件后注册组件
            cesTable
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '3_1_1';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let quartzSearch = currentPageButtons.find(item => {
                return item.menuId == '3_1_1_1';
            });
            if(quartzSearch == undefined){
                _this.quartzSearchFlag = false;
            }else {
                _this.quartzSearchFlag = true;
            }
            //新增按钮权限
            let quartzAdd = currentPageButtons.find(item => {
                return item.menuId == '3_1_1_2';
            });
            if(quartzAdd == undefined){
                _this.quartzAddFlag = false;
            }else {
                _this.quartzAddFlag = true;
            }
            //修改按钮权限
            let quartzModify = currentPageButtons.find(item => {
                return item.menuId == '3_1_1_3';
            });
            if(quartzModify == undefined){
                _this.quartzModifyFlag = false;
            }else {
                _this.quartzModifyFlag = true;
            }
            //删除按钮权限
            let quartzDel = currentPageButtons.find(item => {
                return item.menuId == '3_1_1_4';
            });
            if(quartzDel == undefined){
                _this.quartzDelFlag = false;
            }else {
                _this.quartzDelFlag = true;
            }
        },
        methods:{
            handleClose_quartzConfig(){
                this.dialog_quartzConfig = false;
                this.$refs.quartzConfigForm.resetFields();
            },
            save_quartzConfig(){
                this.$refs["quartzConfigForm"].validate(valid => {
                    if (valid) {
                        const data = new URLSearchParams();
                        data.append('quartzId', this.quartzConfigForm.quartzId);
                        data.append('jobName', this.quartzConfigForm.jobName);
                        data.append('className', this.quartzConfigForm.className);
                        data.append('description', this.quartzConfigForm.description);
                        data.append('cronExpression', this.quartzConfigForm.cronExpression);
                        data.append('status', this.quartzConfigForm.status);
                        data.append('autoStart', this.quartzConfigForm.autoStart);
                        data.append('serverId', this.quartzConfigForm.serverId);
                        if(this.dialogquartzConfigtitile == '新增定时器'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/job/manage/addJobManage`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '新增成功',
                                        type: 'success'
                                    });
                                    this.handleClose_quartzConfig();
                                    this.quartzSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_quartzConfig();
                                }
                            });
                        }else if(this.dialogquartzConfigtitile == '修改定时器'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/job/manage/updateJobManage`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    });
                                    this.handleClose_quartzConfig();
                                    this.quartzSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_quartzConfig();
                                }
                            });
                        }
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });

            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.quartzSearch()
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.quartzSearch()
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //订单查询
            quartzSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'get',
                    url: `${this.$apiPath}/api/job/manage/queryJobManageByPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            //新增
            quartzAdd(){
                this.dialogquartzConfigtitile = '新增定时器';
                this.dialog_quartzConfig = true;
            },
            //修改
            quartzModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '修改操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.dialogquartzConfigtitile = '修改定时器';
                    this.dialog_quartzConfig = true;
                    axios.get(`${this.$apiPath}/api/job/manage/jobManageById/`+ this.merge[0].quartzId).then(res=>{
                        if(res.data.data.status == true){
                            res.data.data.status = '1'
                        }else if(res.data.data.status == false){
                            res.data.data.status = '0'
                        }
                        if(res.data.data.autoStart == true){
                            res.data.data.autoStart = '1'
                        }else if(res.data.data.autoStart == false){
                            res.data.data.autoStart = '0'
                        }
                        res.data.data.serverId = JSON.stringify(res.data.data.serverId)
                        this.quartzConfigForm = res.data.data;
                    })
                }
            },
            //删除
            quartzDel(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '删除操作只能选择单条数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对定时器信息进行删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        axios.get(`${this.$apiPath}/api/job/manage/deleteJobManage/`+ this.merge[0].quartzId).then(res=>{
                            if(res.data.success == true){
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });

                                this.quartzSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            },
            handleClose(done) {
                if (this.loading) {
                    return;
                }
                this.$confirm('确定要提交表单吗？')
                    .then(_ => {
                        this.loading = true;
                        this.timer = setTimeout(() => {
                            done();
                            // 动画关闭需要一定的时间
                            setTimeout(() => {
                                this.loading = false;
                            }, 400);
                            this.quartzSearch();
                        }, 2000);
                    })
                    .catch(_ => {});
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                clearTimeout(this.timer);
            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
</style>
<style scoped>
    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
